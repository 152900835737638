<template>
  <b-overlay :show="busy">
    <div class="card">
      <div class="card-body">
        <div class="row input-row align-items-center">
          <div class="col-12 col-md-6 col-lg-8">
            <h3 class="font-weight-bold d-block mb-4">Addresses</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>

        <address-form
          :affiliate="affiliate"
          :address="selectedAddress"
          @saved="updateAddress"
        />

        <div class="row">
          <div
            class="col-md-6 mb-3"
            v-for="a in addresses"
            :key="'address-col-' + a.id"
          >
            <address-card
              :address="a"
              @edit="showAddressForm"
              @delete="deleteAddress"
            />
          </div>
        </div>

        <div
          class="row my-5"
          v-if="addresses.length === 0"
        >
          <div class="col text-center">
            <div class="mb-3">
              <i class="far fa-exclamation fa-4x text-primary"></i>
            </div>
            <p class="">No addresses found</p>
          </div>
        </div>
      </div>

      <b-row class="submit-buttons">
        <b-col cols="2">
          <button
            type="reset"
            class="btn btn-light"
            @click="$emit('cancel', 'addresses')"
          >
            Cancel
          </button>
        </b-col>
        <b-col cols="10" class="text-right right-col">
          <button
            class="btn btn-secondary previous"
            @click.prevent="$emit('push', 'contact-details')"
          >
            Previous
          </button>
          <button
            class="btn btn-secondary"
            @click="showAddressForm()"
          >
            Add Address
          </button>
        </b-col>
      </b-row>

    </div>
  </b-overlay>
</template>

<script>
import { mapActions } from 'vuex';
import Affiliates from '@/services/Api/Affiliates';
import AddressForm from './address-partials/AddressForm.vue';
import AddressCard from './address-partials/AddressCard.vue';

export default {
  data() {
    return {
      showCreateAddress: false,
      busy: true,
      ready: false,
      addresses: [],
      selectedAddress: {},
    };
  },
  props: {
    affiliate: {
      type: [Object, null],
    },
  },
  methods: {
    ...mapActions({
      setNoticeBar: 'uiHelpers/setNoticeBar',
    }),
    showAddressForm(address) {
      this.selectedAddress = address;
      this.$bvModal.show('address-form');
    },
    prepareAddresses() {
      this.addresses = [...this.affiliate.addresses];
      this.busy = false;
      this.ready = true;
    },
    updateAddress(updatedAddress) {
      const existingAddresses = this.addresses.filter(
        (address) => address.id !== updatedAddress.id,
      ).map((address) => ({
        ...address,
        countryCode: address.country.code,
      }));

      const payload = {
        id: this.affiliate.id,
        addresses: [...existingAddresses, updatedAddress],
      };

      const message = 'The addresses were updated successfully!';
      this.saveAddresses(payload, message);
    },
    deleteAddress(addressToDel) {
      const addresses = this.addresses.filter(
        (address) => address.id !== addressToDel.id,
      ).map((address) => ({
        ...address,
        countryCode: address.country.code,
      }));

      const payload = {
        id: this.affiliate.id,
        addresses,
      };

      const message = 'Your address was deleted successfully!';
      this.saveAddresses(payload, message);
    },
    async saveAddresses(payload, successMessage) {
      this.busy = true;
      try {
        const { data } = await Affiliates.update(payload);
        this.$emit('refresh', data.data);
        this.$emit('push', 'roles');
        this.$snack.success({ text: successMessage });
      } catch (error) {
        this.$snack.danger({
          text: `Something went wrong. ${error}`,
        });
      } finally {
        this.busy = false;
      }
    },
  },
  mounted() {
    this.prepareAddresses();
  },
  components: {
    AddressForm,
    AddressCard,
  },
};
</script>

<style>
.is-invalid input {
  border: 1px solid red;
}

</style>
