<template>
  <div>
    <div class="tabs-wrapper d-flex">
      <b-button
        v-for="(tabItem, tabItemIndex) in tabItems"
        :key="tabItemIndex"
        class="tab-link"
        :class="{
          active:
            $route.query.section === tabItem.name,
        }"
        @click="changeUrlParam(tabItem.name)">
          {{ tabItem.label }}
        </b-button>
    </div>
    <slot name="tab-content"></slot>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  props: {
    tabItems: {
      type: Array,
    },
    defaultTab: {
      type: String,
    },
  },

  created() {
    if (!this.$route.query.section) {
      this.changeUrlParam(this.defaultTab);
    }
  },

  methods: {
    changeSection(tabName) {
      this.$emit('change-tab-section', tabName);
    },

    changeUrlParam(param) {
      this.$router.push({
        name: this.$route.name,
        query: { section: param },
        params: this.$route.params,
      });
    },

    tabIsActive() {
      return this.$route.query.section === this.section;
    },
  },
};
</script>
<style scoped lang='scss'>
@import "@/assets/styles/base/_variables.scss";
@import "@/assets/styles/base/_mixins.scss";

.tabs-wrapper {
  @include media(tablet) {
    overflow: auto;
    white-space: nowrap;
  }

  .btn {
    &.btn-secondary:not(:disabled):not(.disabled) {
      background-color: transparent;
      border: none;
      &.active:focus {
          box-shadow: none;
      }

      &:active {
        color: $brand-dark-blue;

        &:focus {
          box-shadow: none;
        }
      }
    }

    &.tab-link {
      font-size: 1.125rem;
      font-weight: 500;
      border: none;
      color: $brand-dark-blue;
      padding: 0;
      border-radius: 0;
      padding-bottom: 0.5rem;
      margin: 0 1.438rem 1rem 1.438rem;
      border-bottom: 4px solid transparent !important;

      @include media(desktop-only) {
        &:first-child {
          margin-left: 0;
        }
      }

      &.active {
        border: none;
        color: $brand-dark-blue;
        border-bottom: 4px solid $brand-dark-blue !important;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
