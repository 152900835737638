<template>
  <ifac-dropdown
    title="Status"
    inline-title
    v-model="statusValue"
    :list="statuses"
  ></ifac-dropdown>
</template>

<script>
import { IfacDropdown } from '@ifac/ui';
import { mapGetters } from 'vuex';

export default {
  components: {
    IfacDropdown,
  },
  props: {
    status: {
      type: String,
    },
  },
  data() {
    return {
      statusValue: this.status,
    };
  },
  computed: {
    ...mapGetters({
      statuses: 'statuses/data',
    }),
  },
  watch: {
    statusValue(statusValue) {
      this.$emit('status-changed', statusValue);
    },
  },
};
</script>
