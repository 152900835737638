<template>
  <div class="card">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center mb-5">
          <div class="col-12 col-md-6 col-lg-8">
            <h3 class="font-weight-bold d-block mb-4">Login Info</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-12 col-lg-6">
            <ifac-valid-input
              required
              v-model="email"
              name="email"
              title="Email"
              rules="required|max:255"
              placeholder="e.g. New email"
            ></ifac-valid-input>
          </div>
        </div>
      </div>
    </div>
    <b-row class="submit-buttons">
      <b-col cols="2">
        <button
          type="reset"
          class="btn btn-light"
          @click="$emit('cancel', 'login-info')"
        >
          Cancel
        </button>
      </b-col>
      <b-col cols="10" class="text-right right-col">
        <button
          class="btn btn-secondary previous"
          @click.prevent="$emit('push', 'notes')"
        >
          Previous
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          @click.prevent="saveLoginInfo"
        >
          Save
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Affiliates from '@/services/Api/Affiliates';
import { IfacValidInput } from '@ifac/ui';
import { mapMutations } from 'vuex';

export default {
  components: {
    IfacValidInput,
  },
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: this.affiliate.email,
    };
  },
  methods: {
    ...mapMutations({
      setRequestError: 'formHelpers/setError',
    }),
    preparePayload() {
      return {
        email: this.email,
      };
    },
    async saveLoginInfo() {
      try {
        const { data } = await Affiliates.updateLoginInfo(this.affiliate.id, this.preparePayload());
        this.$emit('refresh', data.data);
        this.$snack.success({
          text: 'Login Info updated successfully!',
        });
      } catch (error) {
        if (error.response?.status === 422 || error.response?.status === 403) {
          this.$snack.danger({
            text: error.response.data.error.message,
          });
          this.setRequestError(error.response.data.error);
          return;
        }

        this.$snack.success({
          text: 'Problem updating the email, try again please.',
        });
      }
    },
  },
};
</script>
