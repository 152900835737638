<template>
  <div class="col-12">
    <div class="d-flex align-items-center">
      <div class="d-flex w-75 justify-content-start">
        <b-dropdown
          class="custom-dropdown contact-type"
          :text="selectedType"
          variant="default border"
        >
          <b-dropdown-item
            v-for="contactType in contactTypes"
            @click="selectType(contactType)"
            :key="contactType.id"
          >{{ contactType.name }}
          </b-dropdown-item>
        </b-dropdown>
        <div class="flex-grow-1 ml-2">
          <b-input-group v-if="isPhoneFormat">
            <template #prepend>
              <ifac-search-dropdown
                :options="countriesList"
                placeholder="Country"
                :current="country"
                currentType="object"
                currentValue="prefix"
                optionValue="code3"
                optionTitle="prefix"
                searchAgainst="name,code3,e164,prefix"
                iteratee="prefix"
                emitType="object"
                @selected="onCountrySelected"
              />
            </template>
            <b-form-input
              v-model="phone"
            ></b-form-input>
          </b-input-group>
          <ifac-validator-input
            name="email"
            v-else-if="isEmailFormat"
            v-model="$v.value.info.$model"
          />
          <b-form-input
            v-else
            v-model="value.info"
          />
        </div>
      </div>
      <div class="delete-button-wrapper ml-2 mr-2 mr-md-3 ml-md-3">
        <a href="#" class="cursor-pointer" @click.prevent="deleteValue">
          <i class="far fa-trash"></i>
        </a>
      </div>
      <div class="w-50 d-flex text-center" v-if="format.id === 'email'">
        <div class="w-50">
          <input type="checkbox" v-model="value.notifications.ifac_newsletter">
        </div>
        <div class="w-50">
          <input type="checkbox" v-model="value.notifications.ifac_activities">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p v-if="hasErrors" class="mb-0 mt-1 text-danger small">
          Please provide a valid email address
        </p>
      </div>
    </div>
    <ifac-privacy-selector
      v-model="value.visibility"
      read-only
    />
  </div>
</template>

<script>

import {
  IfacValidatorInput,
  IfacSearchDropdown,
  IfacPrivacySelector,
} from '@ifac/ui';
import { email } from 'vuelidate/lib/validators';

export default {
  components: {
    IfacValidatorInput,
    IfacSearchDropdown,
    IfacPrivacySelector,
  },
  props: [
    'format',
    'value',
    'contactTypes',
    'valueIndex',
    'countriesList',
  ],
  computed: {
    hasErrors() {
      return this.$v.value.info.$error;
    },
    selectionRules() {
      return this.isEmailFormat ? 'email' : '';
    },
    selectedType() {
      return this.contactTypes.find((c) => c.id === this.value.type)?.name;
    },
    isEmailFormat() {
      return this.value.format === 'email';
    },
    isPhoneFormat() {
      return ['phone', 'fax'].includes(this.value.format);
    },
    fullPhone() {
      return this.country
        ? `(${this.country.code3} +${this.country.e164}) ${this.phone}`
        : this.phone;
    },
  },
  validations: {
    value: {
      info: { email },
    },
  },
  data() {
    return {
      phone: null,
      country: null,
    };
  },
  created() {
    if (this.isPhoneFormat) {
      try {
        const { code, number } = this.value.info.match(/\((?<code>[a-zA-Z]{3}) \+.*\)\s*(?<number>.*)/i).groups;
        this.country = this.countriesList.find((c) => String(c.code3) === code);
        this.phone = number;
      } catch {
        this.country = null;
        this.phone = this.value.info;
      }
    }
  },
  methods: {
    onCountrySelected(country) {
      this.country = country;
    },
    selectType(type) {
      this.value.type = type.id;
    },
    deleteValue() {
      this.$emit('delete-contact-value', this.valueIndex);
    },
  },
  watch: {
    country() {
      this.value.info = this.fullPhone;
    },
    phone() {
      this.value.info = this.fullPhone;
    },
    hasErrors(hasErrors) {
      this.$emit('has-errors', hasErrors);
    },
  },
};
</script>
