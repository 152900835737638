<template>
  <div>
    <div class="row input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.title"
          name="title"
          title="Title (Optional)"
          rules="max:32"
          placeholder="e.g. Dr."
          tooltip="Dr., Mr., Mrs., etc"
          :privacy="form.titleVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-valid-input>
      </div>
    </div>
    <div class="row justify-content-between input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.name"
          name="name"
          title="First Name"
          rules="required|max:255"
          required
          :privacy="form.fullNameVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-valid-input>
      </div>
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.surname"
          name="surname"
          title="Last Name"
          rules="required|max:255"
          required
        ></ifac-valid-input>
      </div>
    </div>
    <div class="row input-row">
      <div class="col-12 col-lg-6">
        <ifac-dropdown
          title="Gender (Optional)"
          v-model="form.gender"
          :list="genders"
          :privacy="form.genderVisibility"
          :privacy-read-only="privacyReadOnly"
        />
      </div>
    </div>
    <div class="row input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.disability"
          name="disability"
          title="Disability (Optional)"
          rules="max:255"
          placeholder="e.g. Vision Impairment..."
          tooltip="Vision Impairment, Autism Spectrum Disorder, etc."
          :privacy="form.disabilityVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-valid-input>
      </div>
    </div>
    <div class="row justify-content-between input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          required
          v-model="form.affiliation"
          name="affiliation"
          title="Affiliation"
          rules="required|max:255"
          :privacy="form.affiliationVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-valid-input>
      </div>
      <div class="col-12 col-lg-6">
        <ifac-dropdown
          title="Academia / Government / Industry (Optional)"
          v-model="form.industryAcademiaGovernment"
          :list="industryAcademiaGovernment"
          :privacy="form.industryAcademiaGovernmentVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-dropdown>
      </div>
    </div>
    <div class="row justify-content-between input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.jobPosition"
          name="jobPosition"
          title="Position (Optional)"
          rules="max:255"
          placeholder="e.g. Head of department..."
          tooltip="Head of department, Business analyst…"
          :privacy="form.jobPositionVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-valid-input>
      </div>
      <div class="col-12 col-lg-6">
        <ifac-dropdown
          title="Career Stage (Optional)"
          v-model="form.careerStage"
          :list="careerStages"
          :privacy="form.careerStageVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-dropdown>
      </div>
    </div>
    <div class="row input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.jobDescription"
          name="jobDescription"
          title="Job Description (Optional)"
          rules="max:255"
          placeholder="e.g. Full time researcher..."
          tooltip="Full time researcher, Engineer…"
          :privacy="form.jobDescriptionVisibility"
          :privacy-read-only="privacyReadOnly"
        ></ifac-valid-input>
      </div>
    </div>
    <div class="row input-row">
      <div class="col-12 col-lg-6">
        <ifac-search-dropdown
          title="Country / Region"
          :options="countries"
          placeholder="Select a country / region"
          :current="form.country"
          currentType="object"
          currentValue="name"
          optionValue="code"
          optionTitle="name"
          searchAgainst="name"
          emitType="string"
          emitValue="code"
          :errors="$v.country.$error ? ['This input is required'] : []"
          @selected="form.countryCode = $event"
          :privacy="form.countryVisibility"
          :privacy-read-only="privacyReadOnly"
        />
      </div>
    </div>
    <div class="row input-row">
      <div class="col-12 col-lg-6">
        <ifac-valid-input
          v-model="form.notesForSecretariat"
          name="notesForSecretariat"
          title="General information for the IFAC Secretariat (Optional)"
          rules="max:255"
          placeholder="Write here..."
          tooltip="Only visible to the IFAC Secretariat"
          privacy="private"
          :privacy-read-only="privacyReadOnly"
          multiLine
        ></ifac-valid-input>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IfacValidInput,
  IfacSearchDropdown,
  IfacDropdown,
} from '@ifac/ui';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    IfacValidInput,
    IfacSearchDropdown,
    IfacDropdown,
  },
  props: {
    value: {
      type: Object,
    },
  },
  created() {
    this.form.countryCode = this.value?.country?.code;
    this.emitValidation();
  },
  data() {
    return {
      form: this.value,
      privacyReadOnly: true,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      genders: 'genders/data',
      countries: 'countries/data',
      careerStages: 'careerStages/data',
      industryAcademiaGovernment: 'industryAcademiaGovernment/data',
    }),
    formIsValid() {
      return !this.$v.$invalid;
    },
    country() {
      return this.form?.countryCode;
    },
  },
  validations: {
    country: { required },
  },
  watch: {
    form: {
      deep: true,
      handler(data) {
        this.$emit('form-changed', data);
      },
    },
    country() {
      this.$v.country.$touch();
    },
    formIsValid() {
      this.emitValidation();
    },
  },
  methods: {
    emitValidation() {
      this.$emit('valid', this.formIsValid);
    },
  },
};
</script>
