<template>
  <div class="card">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center mb-5">
          <div class="col-12 col-md-6 col-lg-8">
            <h3 class="font-weight-bold d-block mb-4">Roles In IFAC</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>
        <div
          class="row"
          :key="`${index}_${position.id}`"
          v-for="(position, index) in mx_formattedPositions"
        >
          <div class="col-12">
            <div class="row align-items-center">
              <roles-updater
                :index-value="index"
                :categories="mx_positions"
                :trienniums="mx_trienniums"
                :value="mx_formattedPositions[index]"
                @change-role="updateRole"
                @delete-role="deleteRole"
                @toggle-errors="toggleErrors"
              ></roles-updater>
            </div>
            <hr/>
          </div>
        </div>
        <div class="row input-row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-default border"
              @click="addRole()"
            >
              Add role
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-row class="submit-buttons">
      <b-col cols="2">
        <button
          type="reset"
          class="btn btn-light"
          @click="$emit('cancel', 'roles')"
        >
          Cancel
        </button>
      </b-col>
      <b-col cols="10" class="text-right right-col">
        <button
          class="btn btn-secondary previous"
          @click.prevent="$emit('push', 'addresses')"
        >
          Previous
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :disabled="hasErrors"
          @click.prevent="saveRole"
        >
          Save
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ifacPositionsMixin } from '@ifac/ui';
import Affiliates from '@/services/Api/Affiliates';
import RolesUpdater from '@/views/areas/affiliates/edit-partials/role-partials/RolesUpdater.vue';

export default {
  components: { RolesUpdater },
  mixins: [ifacPositionsMixin],
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      errors: [],
      displayForm: false,
      position: {
        category: null,
        subcategory: null,
        role: null,
        triennium: null,
      },
    };
  },
  computed: {
    currentPositions() {
      return this.affiliate?.positions;
    },
    hasErrors() {
      return !!(this.errors.length);
    },
  },
  methods: {
    toggleErrors(hasError) {
      if (hasError) this.errors.push(hasError);
      else this.errors.shift();
    },
    addRole() {
      const positionData = JSON.parse(JSON.stringify(this.position));
      positionData.id = Math.random().toString(36).substring(2);
      positionData.position = null;
      this.mx_formattedPositions.push(positionData);
    },
    updateRole(i, v) {
      // Set initial position
      v.position = v.role
        ? v.role
        : v.subcategory || v.category;

      this.mx_formattedPositions[i] = v;
    },
    deleteRole(i) {
      this.mx_formattedPositions.splice(i, 1);
    },
    preparePayload() {
      return {
        id: this.affiliate.id,
        positions: [...this.mx_formattedPositions].map((p) => ({
          id: p.position.id,
          trienniumNumber: p.triennium?.id,
          notes: p.notes,
        })),
      };
    },
    async saveRole() {
      try {
        const { data } = await Affiliates.update(this.preparePayload());
        this.$emit('refresh', data.data);
        this.$snack.success({
          text: 'Role updated successfully!',
        });
      } catch (error) {
        console.error(error);
        this.$snack.success({
          text: 'You have some validation errors, try again please.',
        });
      }
    },
  },
};
</script>
