<template>
  <div class="card">
    <div class="card-body">
      <div class="general-details">
        <div class="row align-items-center mb-5">
          <div class="col-12 col-md-6 col-lg-8">
            <h3 class="font-weight-bold d-block mb-4">Notes</h3>
          </div>
          <div class="col align-self-end">
            <slot></slot>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ifac-valid-input
              lines="10"
              multi-line
              title="Notes"
              v-model="notes"
            />
          </div>
        </div>
      </div>
    </div>
    <b-row class="submit-buttons">
      <b-col cols="2">
        <button
          type="reset"
          class="btn btn-light"
          @click="$emit('cancel', 'notes')"
        >
          Cancel
        </button>
      </b-col>
      <b-col cols="10" class="text-right right-col">
        <button
          class="btn btn-secondary previous"
          @click.prevent="$emit('push', 'roles')"
        >
          Previous
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          @click.prevent="saveNotes"
        >
          Save
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Affiliates from '@/services/Api/Affiliates';
import { IfacValidInput } from '@ifac/ui';

export default {
  components: {
    IfacValidInput,
  },
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notes: this.affiliate.notes,
    };
  },
  methods: {
    preparePayload() {
      return {
        id: this.affiliate.id,
        notes: this.notes,
      };
    },
    async saveNotes() {
      try {
        const { data } = await Affiliates.update(this.preparePayload());
        this.$emit('refresh', data.data);
        this.$snack.success({
          text: 'Notes updated successfully!',
        });
      } catch (error) {
        console.error(error);
        this.$snack.success({
          text: 'You have some validation errors, try again please.',
        });
      }
    },
  },
};
</script>
