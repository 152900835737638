<template>
  <b-container class="profile-edit data-entry">
    <div class="header-notice">
      <div class="required-text">
        All fields are required unless otherwise indicated.
      </div>
      <div class="public-data-notice">
        We care about your date, info on
        <span class="options">web public, IFAC public</span> and
        <span class="options">private</span> options
      </div>
    </div>
    <tabs
    :tabItems="TAB_ITEMS"
    defaultTab="general-details">
      <template slot="tab-content">
        <component
          v-if="affiliate"
          :affiliate="affiliate"
          :is="activeTabComponent"
          @refresh="affiliate = $event"
          @push="pushToSection('AffiliatesEdit', $event)"
          @cancel="pushToSection('AffiliatesView', $event)"
        >
          <status-updater :status="form.status" @status-changed="updateStatus"></status-updater>
        </component>
        <router-view />
      </template>
    </tabs>
</b-container>
</template>

<script>
import { IfacLoader, AdminAffiliateProfileTabs } from '@ifac/ui';
import Affiliates from '@/services/Api/Affiliates';
import Tabs from '@/views/components/Tabs.vue';
import Roles from '@/views/areas/affiliates/edit-partials/Roles.vue';
import Notes from '@/views/areas/affiliates/edit-partials/Notes.vue';
import LoginInfo from '@/views/areas/affiliates/edit-partials/LoginInfo.vue';
import Addresses from '@/views/areas/affiliates/edit-partials/Addresses.vue';
import StatusUpdater from '@/views/areas/affiliates/partials/StatusUpdater.vue';
import ContactDetails from '@/views/areas/affiliates/edit-partials/ContactDetails.vue';
import GeneralDetails from '@/views/areas/affiliates/edit-partials/GeneralDetails.vue';

export default {
  components: {
    IfacLoader,
    ContactDetails,
    GeneralDetails,
    StatusUpdater,
    Addresses,
    Roles,
    Notes,
    LoginInfo,
    Tabs,
  },
  data() {
    return {
      busy: false,
      form: {
        id: this.$route.params.id,
        status: null,
      },
      affiliate: null,
    };
  },
  created() {
    this.TAB_ITEMS = AdminAffiliateProfileTabs;
    this.fetchAffiliate();
  },
  computed: {
    activeTabComponent() {
      return this.$route.query.section ?? this.TAB_ITEMS[0]?.name;
    },
  },
  methods: {
    pushToSection(route, section) {
      this.$router.push({
        name: route,
        query: { section },
        params: { id: this.affiliate.id },
      });
    },
    async updateStatus(status) {
      this.form.status = status;
      try {
        await Affiliates.update(this.form);
        this.$snack.success({
          text: 'Status Updated!',
        });
      } catch (error) {
        console.error(error);
        this.$snack.success({
          text: 'Problem updating the status, try again please.',
        });
      }
    },
    async fetchAffiliate() {
      this.busy = true;
      const {
        data: { data },
      } = await Affiliates.show(this.$route.params.id);

      this.affiliate = data;
      this.form.status = this.affiliate.status;
      this.busy = false;
    },
  },
};
</script>
<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.profile-edit {
  padding-bottom: 2.5rem;
  .header-notice {
    padding: 0 0 1rem 0;
    .required-text {
      font-size: 16px;
    }

    .public-data-notice {
      font-size: 14px;
      color: $color-grey-dark;
      .options {
        color: $brand-dark-blue;
      }
    }
  }

  .card {
    padding: 0;
    .submit-buttons {
      padding-bottom: 1.25rem;
    }
  }
}
</style>
