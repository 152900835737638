<template>
  <div class="col-11 col-md-12">
    <div class="row mb-3 align-items-center">
      <div class="col-12 col-lg-4">
        <div class="row align-items-center">
          <div class="col-12">
            <ifac-search-dropdown
              v-model="categoryName"
              v-bind="categorySearchDropdownOptions"
              @selected="category = $event"
            ></ifac-search-dropdown>
          </div>
          <div class="delete-button-wrapper" v-if="!categoryHasChildren">
            <ifac-base-button :icon="['far', 'trash']" @click="deleteValue"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-3 mt-lg-0" v-if="categoryHasChildren">
        <div class="row align-items-center">
          <div class="col-12">
            <ifac-search-dropdown
              v-model="subcategoryName"
              v-bind="subcategorySearchDropdownOptions"
              @selected="subcategory = $event"
            ></ifac-search-dropdown>
          </div>
          <div class="delete-button-wrapper" v-if="!subcategoryHasChildren">
            <ifac-base-button :icon="['far', 'trash']" @click="deleteValue"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-3 mt-lg-0" v-if="subcategoryHasChildren">
        <div class="row align-items-center">
          <div class="col-12">
            <ifac-search-dropdown
              v-model="roleName"
              v-bind="rolesSearchDropdownOptions"
              @selected="role = $event"
            ></ifac-search-dropdown>
          </div>
          <div class="delete-button-wrapper">
            <ifac-base-button :icon="['far', 'trash']" @click="deleteValue"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-lg-4">
        <div class="d-flex align-items-center">
          <div class="d-flex w-100 justify-content-start">
            <ifac-search-dropdown
              v-model="trienniumName"
              v-bind="trienniumsSearchDropdownOptions"
              @selected="triennium = $event"
            ></ifac-search-dropdown>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8" v-if="canAddNotes">
        <div class="d-flex align-items-center">
          <div class="d-flex w-100 justify-content-start">
            <ifac-valid-input
              class="w-100"
              lines="2"
              multi-line
              placeholder="Add notes"
              v-model="notes"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  IfacBaseButton,
  IfacSearchDropdown, IfacValidInput,
} from '@ifac/ui';

export default {
  components: {
    IfacValidInput,
    IfacBaseButton,
    IfacSearchDropdown,
  },
  props: [
    'value',
    'categories',
    'trienniums',
    'indexValue',
  ],
  data() {
    return {
      id: this.value.id,
      category: this.value?.category,
      subcategory: this.value?.subcategory,
      role: this.value?.role,
      triennium: this.value?.triennium,
      notes: this.value?.notes,
    };
  },
  created() {
    if (this.invalid) this.toggleErrors(this.invalid);
  },
  computed: {
    subcategories() {
      const category = this.categories.find((c) => c.id === this.category?.id);
      return category?.children || [];
    },
    roles() {
      const subcategory = this.subcategories.find((c) => c.id === this.subcategory?.id);
      return subcategory?.children || [];
    },
    categoryName() {
      return this.category?.name;
    },
    subcategoryName() {
      return this.subcategory?.name;
    },
    roleName() {
      return this.role?.name;
    },
    trienniumName() {
      return this.triennium?.name;
    },
    categoryHasChildren() {
      return this.subcategories.length > 0;
    },
    subcategoryHasChildren() {
      return this.roles.length > 0;
    },
    entry() {
      return this.$data;
    },
    searchDropdownOptions() {
      return {
        class: 'm-0 w-100',
        currentType: 'object',
        currentValue: 'name',
        optionValue: 'id',
        optionTitle: 'name',
        searchAgainst: 'name',
        emitType: 'object',
        emitValue: 'name',
      };
    },
    categorySearchDropdownOptions() {
      return {
        ...this.searchDropdownOptions,
        options: this.categories,
        current: this.category,
        placeholder: 'Select a category',
      };
    },
    subcategorySearchDropdownOptions() {
      return {
        ...this.searchDropdownOptions,
        options: this.subcategories,
        current: this.subcategory,
        placeholder: 'Select a subcategory',
      };
    },
    rolesSearchDropdownOptions() {
      return {
        ...this.searchDropdownOptions,
        options: this.roles,
        current: this.role,
        placeholder: 'Select a role',
      };
    },
    trienniumsSearchDropdownOptions() {
      return {
        ...this.searchDropdownOptions,
        options: this.trienniums,
        current: this.triennium,
        placeholder: 'Select a triennium',
      };
    },
    invalid() {
      const categoryInvalid = !this.category;
      const subcategoryInvalid = this.categoryHasChildren && !this.subcategory;
      const roleInvalid = this.subcategoryHasChildren && !this.role;
      const trienniumInvalid = !this.triennium;

      return categoryInvalid || subcategoryInvalid || roleInvalid || trienniumInvalid;
    },
    canAddNotes() {
      if (this.categoryName === 'IFAC Fellow') {
        return true;
      }

      if (this.categoryName === 'Award') {
        return true;
      }

      return false;
    },
  },
  methods: {
    deleteValue() {
      this.toggleErrors(false);
      this.$emit('delete-role', this.indexValue);
    },
    toggleErrors(value) {
      this.$emit('toggle-errors', value);
    },
  },
  watch: {
    entry: {
      deep: true,
      handler(entry) {
        if (!this.canAddNotes) {
          entry.notes = null;
        }
        this.$emit('change-role', this.indexValue, entry);
      },
    },
    value: {
      deep: true,
      handler(value) {
        this.category = value.category;
        this.subcategory = value.subcategory;
        this.role = value.role;
        this.triennium = value.triennium;
        this.notes = value.notes;
      },
    },
    category(category) {
      if (!category) {
        this.subcategory = null;
        this.role = null;
      }
    },
    subcategory(subcategory) {
      if (!subcategory) this.role = null;
    },
    invalid(invalid) {
      this.toggleErrors(invalid);
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .delete-button-wrapper {
    display: inline-block;
    position: absolute;
    right: -8.3%;

    .base-button {
      border: 0!important;

      &__icon {
        padding: 0!important;
      }
    }
  }
</style>
